import React, { useState, } from 'react';
import { Card, Select, Input, Modal, Typography, message } from 'antd';
import './DashboardPage.css';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';


const ChangeStatusModal = ({ openModal, setOpenModel, selectOrderId, currentStatus }) => {
  const [failReason, setFailReason] = useState('');
  const [newStatus, setNewStatus] = useState('');
  
  const changeStatus = async (orderId, newStatus) => {
      try {
        const baseUrl = `${backendUrl}/change_order_status/`;
        const url = new URL(baseUrl);
        const response = await fetchWithToken(url.toString(), {
          method: 'POST',
          body: JSON.stringify({
            order_id: orderId,
            status: newStatus,
            failed_reason: newStatus === 'FAILED' ? failReason : undefined,
          })
        });
        if (!response.ok) {
          message.error('Failed to change status', 2);
          
        }else{
          message.success('Change status success!', 2);
        }
        return;
      } catch (error) {
        console.error('Error fetching options:', error);
        return [];
      }
    };
  return (
    <>
      <Modal
        title="Thay đổi trạng thái"
        open={openModal}
        onOk={()=>{
          changeStatus(selectOrderId, newStatus);
          
          setOpenModel(false);
        }}
        onCancel={() => {
          setOpenModel(false);
        }}
      >
        <Card bordered={false} style={{padding:'30px 0'}} >
          <Typography.Text style={{display:'block', margin:'10px 10px 10px 0'}}>
            Trạng thái hiện tại: <strong>{currentStatus}</strong>
          </Typography.Text>
          <Typography.Text style={{marginRight: '10px'}}>
            Trạng thái mới:
          </Typography.Text>
          <Select
            style={{width: 250}}
            showSearch
            placeholder="Chọn trạng thái"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { value: 'NEW', label: 'Mới' },
              { value: 'WAITING_FOR_PAYMENT', label: 'Mẫu đạt - Chờ thanh toán' },
              { value: 'FAILED', label: 'Mẫu không đạt'},
              { value: 'PROCESSING', label: 'Đã thanh toán - Đang xử lý' },
              { value: 'REFUND', label: 'Hoàn trả' },
              { value: 'CANCELLED', label: 'Huỷ' },
            ]}
            onChange={(value) => {
              setNewStatus(value);
              }
            }
          />
          {(newStatus === 'FAILED' || (currentStatus==='FAILED' && newStatus === undefined)) && (
            <div style={{margin: '20px 10px 10px 0'}}>
            <Typography.Text>
              Lý do:
            </Typography.Text>
            <Input
              value={failReason}
              onChange={(data) =>{
                setFailReason(data.target.value);
              }}
            ></Input>
          </div>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default ChangeStatusModal;
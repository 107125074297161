import React, { useState, useCallback, useEffect } from 'react';
import { Card, Button, Input, Modal, Typography, message } from 'antd';
import './DashboardPage.css';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';


const NotifyModal = ({ isOpenNotifyModal, setIsOpenNotifyModal, selectOrderId, curNotifyStatus }) => {
  const [isNotifyModelLoading, setIsNotifyModelLoading] = useState(false);
  const [isNotiDoctor, setIsNotiDoctor] = useState('');
  const [isNotiPatient, setIsNotiPatient] = useState('');
  const [resultExpectDate, setResultExpectDate] = useState('');
  const [reason, setReason] = useState('');
  const [bankAccount, setBankAccount] = useState(''); 
  const [price, setPrice] = useState(0);

  const fetchIsNotified = useCallback( async (order_id) => {
    try {
      const baseUrl = `${backendUrl}/check_notified/`;
      const url = new URL(baseUrl);
      const params = new URLSearchParams();
      url.search = params.toString();
      setIsNotifyModelLoading(true);

      const response = await fetchWithToken(url.toString(), {
        method: 'POST',
        body: JSON.stringify({
          order_id: order_id,
        })
      });
      const data = await response.json();
      setIsNotifyModelLoading(false);
      if (!response.ok) {
        message.error('Failed to check notified', 2);
      }
      setIsNotiDoctor(data.is_notified_doctor);
      setIsNotiPatient(data.is_notified_patient);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    if (isOpenNotifyModal) {
      fetchIsNotified(selectOrderId);
    }
  }, [fetchIsNotified, selectOrderId, isOpenNotifyModal]);
  
  const send_notification = async (orderId, role, reason, bankAccount, price) => {
    try {
      const baseUrl = `${backendUrl}/send_notification/`;
      const url = new URL(baseUrl);
      const response = await fetchWithToken(url.toString(), {
        method: 'POST',
        body: JSON.stringify({
          order_id: orderId,
          role: role,
          bank_number: bankAccount,
          price: price,
          reason: reason
        })
      });
      if (!response.ok) {
        message.error('Failed to send notification', 2);
      }else{
        message.success('Send notification success!', 2);
      }
      setIsNotifyModelLoading(false);
      setIsOpenNotifyModal(false);
      return;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }
  return (
    <>
      <Modal
        title="Gửi thông báo thay đổi trạng thái"
        open={isOpenNotifyModal}
        onCancel={()=>{
          setIsOpenNotifyModal(false);
        }}
        footer={[
          <Button 
            key="submit" 
            type="primary"
            onClick={() => {
              send_notification(selectOrderId, 'patient', reason, bankAccount, price); 
            }}
          >
            Thông báo bệnh nhân
          </Button>,
          <Button 
            key="submit" 
            type="primary"
            hidden={(curNotifyStatus !== 'COMPLETED' && curNotifyStatus !== 'PROCESSING' && curNotifyStatus !== 'DELAY')} 
            onClick={() => {
              send_notification(selectOrderId, 'doctor', reason, bankAccount, price); 
            }}
          >
            Thông báo bác sĩ
          </Button>,
          <Button 
            key="cancel" 
            onClick={() => setIsOpenNotifyModal(false)}
          >
            Huỷ
          </Button>
        ]}
        
      >
        <Card bordered={false} style={{padding:'30px 0'}} >
          {isNotifyModelLoading ? (
            <Typography.Text>Loading...</Typography.Text>
          ) : (
            <> {curNotifyStatus === 'COMPLETED' && (
                <Typography.Text style={{display:'block', margin:'10px 10px 10px 0'}}>
                  Trạng thái thông báo cho bác sĩ: <strong>{isNotiDoctor ? 'Đã thông báo' : 'Chưa thông báo'}</strong>
                </Typography.Text>
              )}
              <Typography.Text style={{display:'block', margin:'10px 10px 10px 0'}}>
                Trạng thái thông báo cho bệnh nhân: <strong>{isNotiPatient ? 'Đã thông báo' : 'Chưa thông báo'}</strong>
              </Typography.Text>
            </>
          )}
        </Card>
        {(curNotifyStatus === 'REFUND') && (<>
            <div style={{margin: '20px 10px 10px 0'}}>
              <Typography.Text>
                Lý do hoàn tiền:
              </Typography.Text>
              <Input
                onChange={(data) =>{
                  setReason(data.target.value);
                }}
              ></Input>
              </div>
              <div>
              <Typography.Text></Typography.Text>
              <Typography.Text>
                Tài khoản nhận trả:
              </Typography.Text>
              <Input
                onChange={(data) =>{
                  setBankAccount(data.target.value);
                }}
              ></Input>
              <Typography.Text>
                Số tiền hoàn trả:
              </Typography.Text>
              <Input
                type='number'
                onChange={(data) =>{
                  setPrice(data.target.value);
                }}
              ></Input>
              </div>
          </>
        )}

      </Modal>
    </>
  );
};

export default NotifyModal;
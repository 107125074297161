import React, { useEffect, useState, } from 'react';
import { Card, Select, Input, Modal, Typography, message } from 'antd';
import './DashboardPage.css';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';
import UploadFileComponent from '../share/UploadFileComponent';


const ChangeResultModal = ({ openModal, setOpenModel, selectOrderId, products }) => {
  const [body, setBody] = useState();

  useEffect(() => {
    setBody(products);
  }, [products]);
  
  const updateResult = async (orderId, data) => {
    if (!data) {
      return;
    }
    try {
      const baseUrl = `${backendUrl}/update_order_products/${orderId}/`;
      const url = new URL(baseUrl);
      const response = await fetchWithToken(url.toString(), {
        method: 'POST',
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        message.error('Failed to change status', 2);
        
      }else{
        message.success('Change status success!', 2);
      }
      return;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  };
  
  const updateExpectDateForBody = (product_id, date) => {
    if (!body){
      return
    }
    let newBody = body
    newBody.map(product => {
      if (product.product.id === product_id) {
        product.result_expect_date = date;
      }
    })
    setBody(newBody);
  }

  const updateFileForBody = (product_id, file_ids) => {
    if (!body){
      return
    }
    let newBody = body
    newBody.map(product => {
      if (product.product.id === product_id) {
        product.file_ids = file_ids;
      }
    })
    setBody(newBody);
  }

  return (
    <>
      <Modal
        title="Cập nhật kết quả"
        open={openModal}
        onOk={()=>{
          updateResult(selectOrderId, body);
          setOpenModel(false);
        }}
        onCancel={() => {
          setOpenModel(false);
        }}
      >
        <Card bordered={false} style={{padding:'10px 0'}} >
          {
            body && body.map((product, index) => (
              <div key={index} style={{marginBottom: '10px'}}>
                <Typography.Title level={5}>{product.product.product_name}</Typography.Title>
                <Typography.Text>
                  Ngày dự kiến có kết quả:
                </Typography.Text>
                <Input
                  type='date'
                  defaultValue={product.result_expect_date && product.result_expect_date}
                  onChange={(data) =>{
                    updateExpectDateForBody(product.product.id, data.target.value);
                  }}
                ></Input>
                <>
                <Typography.Text>File đính kèm</Typography.Text>
                <UploadFileComponent 
                  initListFile={
                    product.files && product.files.map(file => ({
                      uid: file.id,
                      name: file.file_name,
                      status: 'done',
                      url: file.file_path,
                    }))
                  }
                  onResetFiles={(fileList)=>{
                    if(fileList === undefined || fileList.length === 0) {
                      updateFileForBody(product.product.id, []);
                      return;
                    }
                    const ids = fileList.filter(item => item.status === 'done').map(file => file.response? file.response[0].id : file.uid);
                    updateFileForBody(product.product.id, ids);
                  }}
                />
              </>

              </div>
            ))
          }            
        </Card>
      </Modal>
    </>
  );
};

export default ChangeResultModal;
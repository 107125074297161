import React, {useEffect, useState, useCallback} from 'react';
import { Card, Typography, Col, Row, Form, Button, Input, Collapse, Select, message } from 'antd';
import DoctorInput from './DoctorInput';
import PatientInput from './PatientInput';
import ListPriceInput from './ListPriceInput';
import ClinicalInput from './ClinicalInput';
import UploadFileComponent from '../share/UploadFileComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';

const { Title } = Typography;
const { Item } = Form;
const { Panel } = Collapse;

const convertToNumber = (value) => {
  if (value !== undefined && value !== "" && value !== null) {
    return parseInt(value.replace(",", "."));
  }
  return 0;
}
const TestPage = () => {
  const [partnerCode, setPartnerCode] = useState('client');
  const [productIds, setProductIds] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const location = useLocation();
  
  // Extract the path from location.pathname
  const path = location.pathname.split('/').filter(Boolean).pop(); // Gets 'edit-test'
  
  // Extract the query parameter 'id'
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [isEditPage, setIsEditPage] = useState();
  const [idTest, setIdTest] = useState();
  const [initListFile, setInitListFile] = useState([]);
  const [currentDataInEdit, setCurrentDataInEdit] = useState();

  const fetchTests = useCallback( async (idTest) => {
    try {
      const baseUrl = `${backendUrl}/order-detail/${idTest}`;
      const response = await fetchWithToken(baseUrl.toString(), {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setCurrentDataInEdit(data);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    
    if (path === 'edit-test') {
      setIsEditPage(true);
      setIdTest(id);
    }
  }, [path, id]);

  useEffect(() => {
    if(isEditPage) {
      fetchTests(idTest);
    }
  }, [isEditPage]);

  useEffect(() => {
    if(currentDataInEdit) {
      form.setFieldsValue({
        doctor_id: currentDataInEdit.doctor_id.id,
        patient_id: currentDataInEdit.patient_id.id,
        status: currentDataInEdit.status,
        partner_code: currentDataInEdit.partner_code,
        designated_partner: currentDataInEdit.designated_partner,
        patient_id_at_partner: currentDataInEdit.patient_id_at_partner,
        sampling_facility: currentDataInEdit.sampling_facility,
        sampling_date: currentDataInEdit.sampling_date,
        discount_percent: convertToNumber(currentDataInEdit.discount_percent),
        total_amount: convertToNumber(currentDataInEdit.total_amount),
      });
      setPartnerCode(currentDataInEdit.partner_code);
      setProductIds(currentDataInEdit.product_id_list);
      if(currentDataInEdit.files) {
        const initFileIds = currentDataInEdit.files.map(file => file.id);
        setFileIds(initFileIds);
        setInitListFile(currentDataInEdit.files.map(file => ({
          uid: file.id,
          name: file.file_name,
          status: 'done',
          url: file.file_path,
        })));
      }
      if(currentDataInEdit.clinical) {
        form.setFieldsValue({
          clinical: {
            has_mi_cad: currentDataInEdit.clinical.has_mi_cad,
            has_diabetes: currentDataInEdit.clinical.has_diabetes,
            has_hypertension: currentDataInEdit.clinical.has_hypertension,
            total_cholesterol: currentDataInEdit.clinical.total_cholesterol,
            ldl: currentDataInEdit.clinical.ldl,
            hdl: currentDataInEdit.clinical.hdl,
            tg: currentDataInEdit.clinical.tg,
            takes_medications: currentDataInEdit.clinical.takes_medications,
            takes_medications_options: currentDataInEdit.clinical.takes_medications_options,
            other_meds: currentDataInEdit.clinical.other_meds,
            parent_with_ascvd: currentDataInEdit.clinical.parent_with_ascvd,
            smokes: currentDataInEdit.clinical.smokes,
            drinks_alcohol: currentDataInEdit.clinical.drinks_alcohol,
            clinical_notes: currentDataInEdit.clinical.clinical_notes,
          }
        });
      }
    }
  }, [currentDataInEdit]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if(!productIds || productIds.length === 0) {
        message.error('Vui lòng chọn ít nhất 1 sản phẩm', 2);
        return;
      }
      if (values.doctor_id) {
        values.doctor_id = { id: values.doctor_id };
      }
      if (values.patient_id) {
        values.patient_id = { id: values.patient_id };
      }
      if (fileIds && fileIds.length > 0) {
        values.file_ids = fileIds;
      }
      const params = {
        ...values,
        product_id_list: productIds,
        id: idTest,
      }
      
       // Send the form data to the API
       const response = await fetchWithToken(`${backendUrl}/create_order/`, {
        method: 'POST',
        body: JSON.stringify(params), // Send form values as JSON
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      message.success('create order success!', 2);
      navigate('/dashboard');
      

    } catch (error) {
      message.error('Failed to create test: ' + error.message, 2);
    }
  };

  return (
    <>
      <div>
        <Title level={4} style={{textAlign:'left'}}>Tạo xét nghiệm</Title>
      </div>
      <div style={{width:'100%', margin: '30px 0'}}>
        <Card bordered={false} style={{padding:'30px'}} >
          <Form
            form={form}  
            name="basic"
            initialValues={{ remember: true }}
            layout="vertical" // This layout option makes labels appear above the input fields
        >
          <Row>
            <Col className="gutter-ro w" span={8}>
              <DoctorInput isEditPage={isEditPage}/>
            </Col>
            <Col className="gutter-row" span={8}>
              <PatientInput isEditPage ={isEditPage}/>
            </Col>
            <Col className="gutter-row" span={4} style={{paddingRight:'20px'}}>
              <Form.Item>
                <Item
                  label="Trạng thái"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn trạng thái!',
                    },
                  ]}
                  initialValue={'NEW'}
                >
                  <Select
                    showSearch
                    placeholder="Chọn trạng thái"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={[
                      { value: 'NEW', label: 'Mới' },
                      { value: 'FAILED', label: 'Mẫu không đạt'},
                      { value: 'WAITING_FOR_PAYMENT', label: 'Mẫu đạt - Chờ thanh toán' },
                      { value: 'PROCESSING', label: 'Đã thanh toán - Đang xử lý' },
                      { value: 'REFUND', label: 'Hoàn trả' },
                      { value: 'DELAY', label: 'Delay' },
                      { value: 'COMPLETED', label: 'Đã hoàn thành' },
                      { value: 'CANCELLED', label: 'Huỷ' },
                    ]}
                  />
                </Item>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Item
                label="Đối tác"
                name="partner_code"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn đối tác!',
                  },
                ]}
                initialValue={'CLIENT'}
              >
                <Select
                  showSearch
                  placeholder="Đối tác"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={[
                    { value: 'CLIENT', label: 'Cá nhân' },
                    { value: 'Taman', label: 'Tâm An' },
                  ]}
                  onChange={(value) => setPartnerCode(value)} // Step 2: Update state
                />
              </Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={6}>
              <Item
                label="Đơn vị chỉ định"
                name="designated_partner"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn đơn vị chỉ định!',
                  },
                ]}
              >
                <Input />
              </Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Item
                label="Mã bệnh nhân"
                name="patient_id_at_partner"
              >
                <Input />
              </Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Item
                label="Cơ sở lấy mẫu"
                name="sampling_facility"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn cơ sở lấy mẫu!',
                  },
                ]}
              >
                <Input />
              </Item>
            </Col>
            <Col className="gutter-row" span={6}>
            <Item
                label="Ngày lấy mẫu"
                name="sampling_date"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn ngày lấy mẫu!',
                  }
                ]}
              >
                <Input type='date'/>
              </Item>
            </Col>
          </Row>

          <Row>
            <Col className="gutter-row" span={24}>
              <ListPriceInput product_ids={currentDataInEdit?.product_id_list} partner_code={partnerCode} changeProductIds={(productIds)=>{
                setProductIds(productIds);
              }} form={form}/>
            </Col>
          </Row>

          <Row style={{margin: '20px 0'}}>
            <Col className="gutter-row" span={24} style={{padding: 0}}>
              <>
                <Typography.Text>File đính kèm</Typography.Text>
                <UploadFileComponent 
                  initListFile={initListFile}
                  onResetFiles={(fileList)=>{
                    if(fileList === undefined || fileList.length === 0) {
                      setFileIds([]);
                      return;
                    }
                    const ids = fileList.filter(item => item.status === 'done').map(file => file.response? file.response[0].id : file.uid);
                    setFileIds(ids);
                  }}
                />
              </>
            </Col>
          </Row>

          <Row>
            <Col className="gutter-row" span={24}>
              <Collapse defaultActiveKey={['0']}>
                <Panel header="Thông tin lâm sàng" key="1">
                  <ClinicalInput />
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <div style={{display:'flex', justifyContent: 'center', marginTop:'20px'}}>
            <Button type="" style={{boxShadow:'none', margin: '10px', width: '150px'}}
              onClick={()=>{
              navigate('/dashboard');
              }}
            > 
              <span>Thoát</span>
            </Button>
            <Button type="primary" style={{boxShadow:'none', margin:'10px', width: '150px'}} onClick={()=>{
              handleSubmit();
            }}> 
              <span>Lưu mẫu</span>
            </Button>
          </div>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default TestPage;